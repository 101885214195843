import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "components/layout/layout"
import Seo from "components/layout/seo"

import ImgBackground from "components/heroes/ImgBackground"
import TitleBox from "components/titles/Titlebox"

import ContactCTABlock from "components/blocks/ContactCTA"

import SubmenuEnvi from "components/menus/submenu-envi"


const OpenClosedLoopPage = () => {

    // using static header image, load here
    const data = useStaticQuery(
        graphql`
      query {
        heroImage: file(relativePath: { eq: "backgrounds/group-tankers.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2000
            )
          }
        }
      }
    `
    )

    return (
        <Layout>
            <Seo title="ENVI-Marine™ - Open or Closed Loop?" />

            {/* Title banner */}
            <ImgBackground image={data.heroImage}>
                <div className="container m-auto py-10 lg:pb-0 md:pt-40 lg:pt-80 lg:flex">
                    <TitleBox title="Open or Closed Loop?" caption="ENVI-Marine™">

                    </TitleBox>
                </div>
            </ImgBackground>

            {/* Content Area */}
            <section className="py-16 lg:py-32 pb-8 lg:pb-16">
                <div className="container">

                    <div className="lg:flex lg:space-x-12">

                        <div className="mb-12 lg:w-1/4">
                            <SubmenuEnvi className="" />
                        </div>

                        <div className="mb-12 lg:w-3/4 lg:pr-12">

                            <p className="text-2xl mb-12">
                                Depending on the operating area of a ship, it may be preferable to have an open or a closed loop system. Many ships operating close to shore, or spending a lot of time in control zones will opt for a closed loop system. Ships that spend most of their time on the open sea opt for an open mode system.
                            </p>

                            <div className="prose max-w-none">
                                <h3>Open Loop</h3>
                                <p>Single pass raw seawater, scrubber effluent returned to the sea.</p>
                            </div>
                            <StaticImage src="../../images/illustrations/ENVI-Marine--open-loop.jpg" alt="Open Loop Diagram" className="mt-8 mb-32" />

                            <div className="prose max-w-none">
                                <h3>Closed Loop</h3>
                                <p>No overboard discharge permitted. Washwater is recirculated with NaOH added to neutralize sulphur. Solution is processed and contaminants are stored for disposal ashore.</p>
                            </div>
                            <StaticImage src="../../images/illustrations/ENVI-Marine--closed-loop.jpg" alt="Closed Loop Diagram" className="mt-8" />

                        </div>
                    </div>



                </div>
            </section>



            {/* CTA contact block */}
            <section className="bg-primary-50 py-16 lg:py-32">
                <ContactCTABlock />
            </section>

        </Layout>
    )
}

export default OpenClosedLoopPage
